import LernFragen from 'lf/LernFragen';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import ListRenderer from 'ln/list/ListRenderer';
import LinkedList from 'ln/linkedlist/LinkedList';
import Slide from 'lf/slides/Slide/SlideModel';
import List from 'ln/list/List';
import View from 'ln/view/View';
import Model from 'ln/model/Model';
import Question from 'lf/slides/Question/Question';
import BooleanBranchModel from './BooleanBranchModel';
import SlideRenderer from 'lf/sliderenderer/SlideRenderer';
import EvaluateAnswersDeco from 'lf/decorators/EvaluateAnswers';

import BinaryChoice from './BinaryChoice';
import BinaryChoiceModel from './BinaryChoiceModel';

import setup, { SetupConfig } from 'ln/setup/setup';


/**
 * A class that represents a hotspot question.
 * 
 * An example of a slide model:
 * 
 *	var model = {
 *		"modelName":"BooleanBranch",
 *		"title": "Boolean Branch Demo",
 *		"description": "Choose wether there is a fault in the image or not.",
 *		"image": "./img/teamfoto-lernetz.jpg",						
 *	}
 * 
 */
class BooleanBranch extends Question {

	/**
	 * The model
	 */
    public model: BooleanBranchModel;
	
	private config: any;

    /**
	 * Constructs a BooleanBranch View
	 */
	constructor( model: BooleanBranchModel, slideRenderer: SlideRenderer ) {
		super( model, slideRenderer );

		// set default values
		this.defaultTemplate = this.model.get( 'template', 'lf.booleanbranch-slide' );
    }

	protected init() {
		super.init();

        this.bindUI();

        
        //this.excursionPlayer = new LernFragen().defaultSetup();
    }
    
    private onClickStop( n?:Node, event?:MouseEvent ) {
        this.model.selectChoice( true );
		
		this.expandBranch();
	}
	
	private expandBranch() {
		var next = this.model.next;

		var slides = this.model.get( 'slides' );
		var first = slides[0];
		var last = slides[ slides.length - 1 ];

		if( slides.length == 2 ) {
			first.addNext( last, true );
			last.addPrevious( first, true );

		}

		this.model.addNext( first, true );
		if( first ) first.addPrevious( this.model, true );

		if( next && last ) last.addNext( next, true );
		if( next ) next.addPrevious( (last)?last:this.model, true );
	}

    private onClickOkay( n?:Node, event?:MouseEvent ) {
        this.model.selectChoice( false );
		
		this.expandBranch();

		// mark next model as answered incorrectly!

		var next = this.model.next as any;
		next.markAsAnswered()
	}
	
	public onChooseStop() {
		this.onClickStop();
	}

	public onChooseOkay() {
		this.onClickOkay();
	}

	/**
	 * Binds the click event for adding points
	 */
	bindUI() {
        /*this.node.js( 'choice-yes' ).click.add( this.onClickYes, this );
        this.node.js( 'choice-no' ).click.add( this.onClickNo, this );*/
	}

	/**
	 * Unbinds the click event for disabling to add points
	 */
	unbindUI() {
        /*this.node.js( 'choice-yes' ).click.remove( this.onClickYes, this );
        this.node.js( 'choice-no' ).click.remove( this.onClickNo, this );*/
	};

}

export default BooleanBranch;