// import lib
import View from 'ln/view/View';
import LinkedList from 'ln/linkedlist/LinkedList';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';

// import project
import SlideModel from 'lf/slides/Slide/SlideModel';
import SlideView from 'lf/slides/Slide/Slide';
import SlideRenderer from 'lf/sliderenderer/SlideRenderer';
import CorrectWrong from 'lf/aggregators/CorrectWrong';

import BooleanBranchModel from '../slides/BooleanBranchModel';
import BinaryChoiceModel from '../slides/BinaryChoiceModel';


/**
 * This class can render status infos like correctSlide, totalSlide, correctSlides, wrongSlides, percentages into a template.
 */
class Status extends View {

	private slideRenderer: SlideRenderer;
	private data:{ currentSlide:number,	totalSlides: number, percentage: number, correctSlides: number,	wrongSlides: number };

	constructor( slideRenderer: SlideRenderer ) {

		super();
		this.defaultTemplate = 'lf.status';
		this.data = { currentSlide:0, totalSlides:0, percentage:0, correctSlides:0, wrongSlides:0 };

		this.slideRenderer = slideRenderer;
		this.slideRenderer.slideChanged.add( this.update.bind( this ) );
		this.slideRenderer.userAnswered.add( this.update.bind( this ) );

	}


	/**
	 */
	private update() {

		var slides = this.slideRenderer.currentModel.toArray();

		var aggregator = new CorrectWrong();
		aggregator.aggregate( slides as SlideModel[] );

        var slideCount = 0;
        slides.forEach( function( slide ) {
            if( slide instanceof BooleanBranchModel || slide instanceof BinaryChoiceModel ) {
                slideCount++;
            }
        })

        var currentSlide = 0;
        // because we omit hotspotslides we need to rule those out
        for( var i = 0; i <= slides.indexOf( this.slideRenderer.currentModel ) ; i ++ ) {
            if( slides[i] instanceof BooleanBranchModel || slides[i] instanceof BinaryChoiceModel ) {
                currentSlide++;
            }
        }

		this.data = {
			currentSlide: currentSlide,
			totalSlides: slideCount,
			percentage: aggregator.percentage,
			correctSlides: aggregator.correctSlides,
			wrongSlides: aggregator.wrongSlides,
		}

		// rerender
		this.node.html = "";
		this.render( this.node );
	}

	renderData() {
		return this.data;
	}

}

export default Status;