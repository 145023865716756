import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import View from 'ln/view/View';
import Model from 'ln/model/Model';
import Question from 'lf/slides/Question/Question';
import BinaryChoiceModel from './BinaryChoiceModel';
import SlideRenderer from 'lf/sliderenderer/SlideRenderer';


/**
 * A class that represents a hotspot question.
 * 
 * An example of a slide model:
 * 
 *	var model = {
 *		"modelName":"BinaryChoice",
 *		"title": "Binary Choice Demo",
 *		"description": "Choose wether there is a fault in the image or not.",
 *		"image": "./img/teamfoto-lernetz.jpg",						
 *	}
 * 
 */
class BinaryChoice extends Question {

	/**
	 * The model
	 */
	public model: BinaryChoiceModel;

    /**
	 * Constructs a BinaryChoice View
	 */
	constructor( model: BinaryChoiceModel, slideRenderer: SlideRenderer ) {
		super( model, slideRenderer );

		// set default values
		this.defaultTemplate = this.model.get( 'template', 'lf.binarychoice-slide' );
	}

	protected init() {
		super.init();

        this.bindUI();
    }
    
    private onClickOkay( n?:Node, event?:MouseEvent ) {
        this.model.selectChoice( true );
    }

    private onClickStop( n?:Node, event?:MouseEvent ) {
       this.model.selectChoice( false );
	}
	
	public onChooseStop() {
		this.onClickStop();
	}

	public onChooseOkay() {
		this.onClickOkay();
	}


	/**
	 * Binds the click event for adding points
	 */
	bindUI() {
        /*this.node.js( 'choice-yes' ).click.add( this.onClickYes, this );
        this.node.js( 'choice-no' ).click.add( this.onClickNo, this );*/
	}

	/**
	 * Unbinds the click event for disabling to add points
	 */
	unbindUI() {
        /*this.node.js( 'choice-yes' ).click.remove( this.onClickYes, this );
        this.node.js( 'choice-no' ).click.remove( this.onClickNo, this );*/
	};

}

export default BinaryChoice;