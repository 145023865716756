import QuestionModel from 'lf/slides/Question/QuestionModel';
import LernFragen from 'lf/LernFragen';
import SlideModel from 'lf/slides/Slide/SlideModel';
import Hotspot from 'lf/slides/HotSpot/HotspotModel';	


/**
 * A model class for DragDrop slides.
 */
export class BooleanBranchModel extends QuestionModel {

	/**
	 * The constructor
	 */
	constructor( json ) {
		super( json );
		this.complete();
	}

	/**
	 * Complete and adjusts the model
	 */
	public complete() {
		
	}

	public selectChoice( selectedChoice:boolean ) {
		this.choice = selectedChoice;

		this.fireUserInput();
	}

	public hasUserInput():boolean {
		return (this.choice === undefined)?false:true;
	};

	public isCorrect():boolean {
		if( this.slides ) {
			var allCorrect:boolean = true;
			this.slides.forEach( function( slide:SlideModel ) {
				if( slide instanceof Hotspot ){
					if( slide.isAnswered() == true && slide.isCorrect() == false ) {
						allCorrect = false;
					}
				}
			})
			return allCorrect;
		} else {
			return (this.choice === undefined) ? false : this.choice;
		}
	};

	set choice( choice:boolean ) {
		this.set( 'userchoice', choice );
	}

	get choice(): boolean {
		return this.get( 'userchoice' );
	}

}

export default BooleanBranchModel;