// import lib
import View from 'ln/view/View';
import Scanner from 'ln/view/Scanner';
import Signal from 'ln/signal/Signal';
import Node from 'ln/node/Node';
//import Slide from '../slides/Slide/SlideModel';
//import SlideRenderer from './sliderenderer/SlideRenderer';
import Mapper from 'ln/model/Mapper';
/*import { common as commonModels } from './setup/models';
import { all as commonViews } from './setup/lernfragen';Si
import { common as commonSlides } from './setup/slides';*/

export interface NavigationListener {
	( ):void;
}

/**
 * Main class for LernFragen quizzes
 */
class Result extends View {

    public gotoCertificate:Signal<NavigationListener>;
    
	public data:{ points:number, total:number, language:string };
	
	// threshold for showing the certificate
	private certificateThreshold:number = 100;

	constructor() {
        super();
        
        this.gotoCertificate = new Signal<NavigationListener>();

        this.data = {
            points: 0,
			total: 0,
			language: 'de'
        };

		this.defaultTemplate = 'lf.result';

	}

	public init() {
        this.node.js('certificate').click.add( function() {
            this.gotoCertificate.dispatch();
		},this);
		
		this.node.js('repeat').click.add( function() {
			// repeat the challenge
			window.location.href = window.location.href;
		}, this );

		this.toggleButtons();

	}

	public render( target?:Node ) : View {
		return super.render( target );
	}

	/**
	 * Default setup with all slides, models and views.
	 */
	public defaultSetup() {
		//return this.setup( commonSlides, commonModels, commonViews );
    }

    public setData( points:number, total:number ) {
        this.data.points = points;
		this.data.total = total;
		this.toggleButtons();
    }
    
    public renderData() {
        return this.data;
	}
	
	private toggleButtons() {
		if( this.data.total == 0 || this.node == undefined) return;
		if( this.data.points / (this.data.total / 100) < this.certificateThreshold ) {
			// repeat
			this.node.js('certificate').addClass('hidden');
			this.node.js('repeat').removeClass('hidden');
		} else {
			// certificate
			this.node.js('certificate').removeClass('hidden');
			this.node.js('repeat').addClass('hidden');
		}
	}

}


export default Result;