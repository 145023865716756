import Node from 'ln/node/Node';
import Decorator from './Decorator';
import { DecoratorFunction } from './Decorator';
import IoC from 'ln/ioc/IoC';
import SlideModel from '../slides/Slide/SlideModel';
import SlideView from '../slides/Slide/Slide';
import Hotspot from '../slides/HotSpot/Hotspot';
import DragDrop from '../slides/DragDrop/DragDrop';


class DisableInputs extends Decorator{

    public ioc:IoC<DecoratorFunction> = new IoC<DecoratorFunction>();

    constructor(){
        super();
        this.ioc = new IoC<DecoratorFunction>();
        this.setup();
    }

    setup() {
        this.ioc.add( 'default', function( model:SlideModel, view:SlideView ) {} );
        this.ioc.add( 'SingleChoice', this.disableInputs );
        this.ioc.alias( 'App\\SingleChoice', 'SingleChoice' );
        this.ioc.add( 'MultipleChoice', this.disableInputs );
        this.ioc.alias( 'App\\MultipleChoice', 'MultipleChoice' );
        this.ioc.add( 'MatrixChoice', this.disableInputs );
        this.ioc.alias( 'App\\MatrixChoice', 'MatrixChoice' );
        this.ioc.add( 'MatrixBinaryChoice', this.disableInputs );
        this.ioc.alias( 'App\\MatrixBinaryChoice', 'MatrixBinaryChoice' );
        this.ioc.add( 'Cloze', this.disableInputs );
        this.ioc.alias( 'App\\Cloze', 'Cloze' );
        this.ioc.add( 'Freetext', this.disableInputs );
        this.ioc.alias( 'App\\Freetext', 'Freetext' );
        this.ioc.add( 'DropDown', this.disableInputs );
        this.ioc.alias( 'App\\DropDown', 'DropDown' );

        this.ioc.add( 'Hotspot', this.disableClick );
        this.ioc.alias( 'App\\Hotspot', 'Hotspot' );

        this.ioc.add( 'DragDrop', this.disableDrag );
        this.ioc.alias( 'App\\DragDrop', 'DragDrop' );
        this.ioc.add( 'DragDropCloze', this.disableDrag );
        this.ioc.alias( 'App\\DragDropCloze', 'DragDropCloze' );
        this.ioc.add( 'DragDropSentence', this.disableDrag );
        this.ioc.alias( 'App\\DragDrop', 'DragDropSentence' );
        this.ioc.add( 'Order', this.disableDrag );
        this.ioc.alias( 'App\\Order', 'Order' );
    }

	public decorate( slideModel:SlideModel, slideView:SlideView ) {
		var key = slideView.decoratorKey;
		this.ioc.get( slideView.decoratorKey ).call( this, slideModel, slideView );
	}

    /**
     * Disables all inputs, textareas or selects.
     */
    disableInputs( model:SlideModel, view:SlideView ) {
        
        view.node.all( 'input, textarea, select' ).forEach( element => {
            element.setAttribute( 'disabled', 'true' );
        });

        // prevent default behaviour of labels
        view.node.all( 'label' ).forEach( label => {
            label.click.add( function( n:Node, e:MouseEvent ){
                e.stopImmediatePropagation();
            })
        });
    };

    /**
     * Disables clicking e.g on hotspots
     */
    disableClick( model:SlideModel, view:Hotspot ){
        view.unbindUI();
    }

    /**
     * Disables drag elements
     */    
    disableDrag( model:SlideModel, view:DragDrop ){
        view.unbindUI();
    }

}

export default DisableInputs;



