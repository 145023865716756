// import lib
import { isType } from 'ln/js';
import Node from 'ln/node/Node';
import Window from 'ln/node/Window';
import Model from 'lf/slides/Slide/SlideModel';

// import project
import ContainerFeedback from 'lf/decorators/ContainerFeedback';
import SlideModel from 'lf/slides/Slide/SlideModel';
import SlideView from 'lf/slides/Slide/Slide';
import QuestionModel from 'lf/slides/Question/QuestionModel';
import QuestionView from 'lf/slides/Question/Question';
import AnswerListModel from 'lf/slides/AnswerList/AnswerListModel';
import AnswerListView from 'lf/slides/AnswerList/AnswerList';
import BooleanBranchModel from '../slides/BooleanBranchModel';
import BinaryChoiceModel from '../slides/BinaryChoiceModel';
import HotspotModel from 'lf/slides/HotSpot/HotspotModel';


export interface ChallengeFeedbackConfig {
	posFeedbackProperty?: string;
	negFeedbackProperty?: string;
	posFeedback?: string;
	negFeedback?: string;
	feedbackNode?: Node;
}


class ChallengeFeedback extends ContainerFeedback {

	private posFeedbackProperty: string;
	private negFeedbackProperty: string;
	private posFeedback: string;
	private negFeedback: string;
	private feedbackNode: Node;


	constructor( config: ChallengeFeedbackConfig ) {
		super( {} );

	/*	ContainerFeedback.ioc.add( 'BinaryChoice', function ( slideModel: BinaryChoiceModel, slideView: BinaryChoice ) {
			this.showFeedback( slideView.node, slideModel.isCorrect(), slideModel );
		} ); 
		ContainerFeedback.ioc.alias( 'App\\BinaryChoice', 'BinaryChoice' );*/
	
		ContainerFeedback.ioc.add( 'BooleanBranch', function( slideModel: BooleanBranchModel, slideView: BooleanBranch ) {
			this.showFeedback( slideView.node, slideModel.isCorrect(), slideModel );
		});
		ContainerFeedback.ioc.alias( 'App\\BooleanBranch', 'BooleanBranch' );

		/**
		 * The name of the property to lookup of the model for a positive feedback 
		 * @default ''
		 */
		this.posFeedbackProperty = ( config.posFeedbackProperty ) ? config.posFeedbackProperty : 'positiveFeedback';

		/**
		 * The name of the property to lookup of the model for a negative feedback 
		 * @default ''
		 */
		this.negFeedbackProperty = ( config.negFeedbackProperty ) ? config.negFeedbackProperty : 'negativeFeedback';

		/**
		 * The fallback positive feedback that is shown for decorator if no posFeedbackProperty is found on the model
		 * @default This is correct!
		 */
		this.posFeedback = ( config.posFeedback ) ? config.posFeedback : 'This is correct!';

		/**
		 * The fallback negative feedback that is shown for decorator if no negFeedbackProperty is found on the model
		 * @default This is wrong!
		 */
		this.negFeedback = ( config.negFeedback ) ? config.negFeedback : 'This is wrong!';

		this.feedbackNode = ( config.feedbackNode ) ? config.feedbackNode: undefined ; 

	}

	/**
	 * Shows the feedback and marks it correct/wrong
	 * @param correctlyAnswered True if the question was correctly answered false otherwise.
	 * @param parentNode The parent node where the container is added.
	 * @param model The model to read the model specific feedback properties
	 */
	public showFeedback( parentNode: Node, correctlyAnswered: boolean, model: Model ) {

		var feedback = ( correctlyAnswered ) ? this.getFallbackFeedback( model.get( this.posFeedbackProperty ), this.posFeedback ) : this.getFallbackFeedback( model.get( this.negFeedbackProperty ), this.negFeedback );

		if( this.feedbackNode ) parentNode = this.feedbackNode;

		// only show feedback if there is any.
		if ( feedback ) {
			this.showContainer( parentNode, feedback );
			this.clearSolutionOn( this.getContainer( parentNode ) );
			this.decorateSolution( model, this.getContainer( parentNode ), correctlyAnswered );
		}
	};

	public decorate(  slideModel:SlideModel, slideView:SlideView ) {
		if( slideModel instanceof BooleanBranchModel || slideModel instanceof BinaryChoiceModel || slideModel instanceof HotspotModel ) {
			this.showFeedback( slideView.node, (slideModel as QuestionModel).isCorrect(), slideModel );
		}
	}

	protected decorateSolution( model: Model, node:Node , bool:boolean ) {

		var boolTrue = '-correct';
		var boolFalse = '-wrong';

		if( model instanceof BooleanBranchModel ) {
			boolTrue = '-neutral';
		}
		
		node.addClass( ( bool ) ? boolTrue : boolFalse );
	};

	protected clearSolutionOn( node:Node ) {
		node.removeClass( '-neutral' );
		node.removeClass( '-wrong' );
		node.removeClass( '-correct' );
	};

	public showContainer( parentNode, content ) {

		var container = this.getContainer( parentNode );

		// no node found - create a new one and inject
		if ( container === null ) {
			container = Node.fromTag( 'div' );
			container.addClass( 'feedback-placeholder', 'feedback-panel' );
			parentNode.append( container );
		}

		// set the according feedback

		container.html = content;
	};

	/**
	 * Returns the fallback feedback based on its type.
	 * If its an array it takes a random one.
	 * If string it returns the string.
	 */
	private getFallbackFeedback = function ( modelValue, fallback ) {
		if ( isType( modelValue, "String" ) && modelValue.length > 0 ) return modelValue;
		if ( isType( fallback, "String" ) ) return fallback;
		if ( isType( fallback, "Array" ) ) return fallback[Math.floor( Math.random() * fallback.length )];
	}

	protected onSlideChanged( currentModel: SlideModel, currentView: SlideView ) {
	}

}

export default ChallengeFeedback;


/**
 * Decorates the question slides with a container to show if the user has correctly answered the question.
 * @param slideView The slide view to decorate.
 * @param slideModel The corresponding slide model.
 * @method answerList
 */
var defaultClosure = function ( slideModel: QuestionModel, slideView: QuestionView ) {
	this.showFeedback( slideView.node, slideModel.isCorrect(), slideModel );
};

ChallengeFeedback.ioc.add( 'Freetext', defaultClosure );
ChallengeFeedback.ioc.alias( 'App\\Freetext', 'Freetext' );
ChallengeFeedback.ioc.add( 'SingleChoice', defaultClosure );
ChallengeFeedback.ioc.alias( 'App\\SingleChoice', 'SingleChoice' );
ChallengeFeedback.ioc.add( 'MultipleChoice', defaultClosure );
ChallengeFeedback.ioc.alias( 'App\\MultipleChoice', 'MultipleChoice' );
ChallengeFeedback.ioc.add( 'Hotspot', defaultClosure );
ChallengeFeedback.ioc.alias( 'App\\Hotspot', 'Hotspot' );
ChallengeFeedback.ioc.add( 'DragDrop', defaultClosure );
ChallengeFeedback.ioc.alias( 'App\\DragDrop', 'DragDrop' );
ChallengeFeedback.ioc.add( 'Cloze', defaultClosure );
ChallengeFeedback.ioc.alias( 'App\\Cloze', 'Cloze' );
ChallengeFeedback.ioc.add( 'DropDown', defaultClosure );
ChallengeFeedback.ioc.alias( 'App\\DropDown', 'DropDown' );
ChallengeFeedback.ioc.add( 'Order', defaultClosure );
ChallengeFeedback.ioc.alias( 'App\\Order', 'Order' );
ChallengeFeedback.ioc.add( 'DragDropCloze', defaultClosure );
ChallengeFeedback.ioc.alias( 'App\\DragDropCloze', 'DragDropCloze' );
ChallengeFeedback.ioc.add( 'DragDropSentence', defaultClosure );
ChallengeFeedback.ioc.alias( 'App\\DragDropSentence', 'DragDropSentence' );
ChallengeFeedback.ioc.add( 'MatrixChoice', defaultClosure );
ChallengeFeedback.ioc.alias( 'App\\MatrixChoice', 'MatrixChoice' );
ChallengeFeedback.ioc.add( 'MatrixBinaryChoice', defaultClosure );
ChallengeFeedback.ioc.alias( 'App\\MatrixBinaryChoice', 'MatrixBinaryChoice' );