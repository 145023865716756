import LernFragen from 'lf/LernFragen';
import Node from 'ln/node/Node';
import LinkedList from 'ln/linkedlist/LinkedList';
import Slide from 'lf/slides/Slide/SlideModel';
import Scorm from 'lf/scorm/Scorm';
import Request from 'ln/request/Request';
import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';

import SlideModel from 'lf/slides/Slide/SlideModel';
import SlideView from 'lf/slides/Slide/Slide';

import "./templates/lernfragen";
import "./templates/slides";
import "./templates/icons";

import Window from 'ln/node/Window';

import EvaluateAnswersDeco from 'lf/decorators/EvaluateAnswers';
import DisableInputs from 'lf/decorators/DisableInputs';
import ShowCorrectDeco from 'lf/decorators/ShowCorrect';
import RelatedFeedbackDeco from 'lf/decorators/RelatedFeedback';
import ChallengeFeedbackDeco from './decorators/ChallengeFeedback';
import TransitionSlideRenderer from 'lf/sliderenderer/TransitionSlideRenderer';
import { TransitionType } from 'lf/sliderenderer/TransitionSlideRenderer';
import CorrectWrongAggregator from 'lf/aggregators/CorrectWrong';
import ChallengeNavigation from './navigation/ChallengeNavigation';

import BinaryChoice from './slides/BinaryChoice';
import BinaryChoiceModel from './slides/BinaryChoiceModel';

import BooleanBranch from './slides/BooleanBranch';
import BooleanBranchModel from './slides/BooleanBranchModel';

import Hotspot from 'lf/slides/HotSpot/Hotspot';
import HotspotModel from 'lf/slides/HotSpot/HotspotModel';

import ResultView from './views/Result';
import CertificateFormView from './views/CertificateForm';

import SlideRenderer from 'lf/sliderenderer/SlideRenderer';

import Status from './navigation/Status';

import setup, { SetupConfig } from 'ln/setup/setup';

import { shuffle } from 'ln/js';
import Lang from 'ln/lang/Lang';

export function init( config:SetupConfig ){
	
	/** CHALLENGE CONFIGURATION **/
	var slidesInChallenge = 20;
	var randomizeSlides = true;

	Node.body.addClass( '-hidden' );

	Window.resize.add( function( window) {
		setSizes();
	}, this)
	Window.scroll.add( function( window) {
		setSizes();
	}, this)
	

    Template.context.setup = setup;
    Template.context.image = function( image:{ file_name:string }, preset:string = 'medium', ext:string = 'png' ){
		if( image == undefined ) return '';
		if( !image.file_name ) return '';
		
		return decodeURIComponent( setup.route( 'asset', { file_name:  image.file_name, preset:preset, ext:ext } ).url() );
	}

	Template.context.icon = function( name:string, classes?:string, viewBox?:string ) {
        return Template.render( 'lf.icon', { name:name, classes:( classes ) ? classes : 'svg-icon', viewBox: (viewBox)? viewBox: '0 0 100 100' } );
    }
    
	setup.init( config );
	Lang.add( setup.data( 'lang' ) );

	var lf = new LernFragen().defaultSetup();

	lf.scanner.ioc.add( 'challenge-navigation', function( node:Node, lernfragen:LernFragen ) {
		var challengeNavi = new ChallengeNavigation( lernfragen.renderer ).render( node );
		// event for when the challenge is finished
		challengeNavi.challengeFinished.add( function() {
			
			var current = lf.renderer.currentModel;
			var total = 0;
			var points = 0;
			while( current !== undefined) {
				if( current instanceof BooleanBranchModel || current instanceof BinaryChoiceModel ){
					total += 1;
					if( current.isCorrect() ) points += 1;
				}
				current = current.previous;
			}
			lf.node.addClass('hidden');

			Node.body.removeClass( '-hidden' );
			result.setData( points, total);
			result.render( Node.one('#result'));

			Node.js('footer').removeClass( 'hidden' )
		}, this);
		return challengeNavi;
	});

	lf.scanner.ioc.add( 'status', function( node:Node, lernfragen:LernFragen ) {
		return new Status( lernfragen.renderer ).render( node );
	});

	lf.mapper.toModel.add( 'BinaryChoice', function ( json ) {
		return new BinaryChoiceModel( json );
	});

	lf.mapper.toModel.alias( 'App\\BinaryChoice', 'BinaryChoice' );

	lf.mapper.toModel.add( 'BooleanBranch', function ( json ) {
		return new BooleanBranchModel( json );
	});
	lf.mapper.toModel.alias( 'App\\BooleanBranch', 'BooleanBranch' );

	// render lernfragen
	lf.render( Node.one( '#lernfragen' ) );
	var mappedData = lf.mapper.model( setup.data( 'module' ) );

	// build a linked list with all slides of level 1
	/*var slides = [];
	mappedData.forEach( chapter => {
		slides = slides.concat( chapter.level( 1 ) as Slide[] );
	});*/
	var slides = mappedData.slides;

	// Randomize
	if( randomizeSlides == true ) slides = shuffle( slides );

	// and limit to specified length
	if( slidesInChallenge > 0 ) {
		slides = slides.slice( 0, slidesInChallenge );
	}

	var first = LinkedList.fromArray( slides ); 

	// setup decorators
	var evaluate = new EvaluateAnswersDeco();

	evaluate.ioc.add( 'BinaryChoice', function( model: BinaryChoiceModel, view: BinaryChoice) {
		// decorate accordingly
	} );
	evaluate.ioc.alias( 'App\\BinaryChoice', 'BinaryChoice' );

	evaluate.ioc.add( 'BooleanBranch', function( model: BooleanBranchModel, view: BooleanBranch ) {
		// decorate accordingly'
	});
	evaluate.ioc.alias( 'App\\BooleanBranch', 'BooleanBranch' );

	var hotSpot = function(  model:HotspotModel, view:Hotspot ) {

		var pointNodes = view.getPointNodes();
		var userPoints = model.userPoints;
		var result = model.partitionUserPoints();
		
		pointNodes.forEach( ( pointNode, index ) => {
			this.decorateAnswer( pointNode, result.correct.indexOf( userPoints[ index ] ) > -1, true );
		});
		
		view.renderHotspotAreas();

		// you have only one try!
		model.markAsAnswered();
		if( !(model.next instanceof BooleanBranchModel) && !(model.next instanceof BinaryChoiceModel) && !(model.next instanceof HotspotModel) ) {
			lf.renderer.show( model.next );
		}
	
	};

	evaluate.ioc.add( 'Hotspot', hotSpot );
	evaluate.ioc.alias( 'App\\Hotspot', 'Hotspot' );

	lf.renderer.userInput.add( evaluate.decorate.bind( evaluate ) );

	var disable = new DisableInputs();
	disable.ioc.add( 'BooleanBranch', function( model: BooleanBranchModel, view: BooleanBranch ) {
		//nothing to do here
	});
	disable.ioc.alias( 'App\\BooleanBranch', 'BooleanBranch' );

	disable.ioc.add( 'BinaryChoice', function( model: BinaryChoiceModel, view: BinaryChoice ) {
		//nothing to do here
	});
	disable.ioc.alias( 'App\\BinaryChoice', 'BinaryChoice' );

	lf.renderer.userAnswered.add( disable.decorate.bind( disable ) );


	var challengeFeedback = new ChallengeFeedbackDeco( { posFeedbackProperty: 'positiveFeedback', negFeedbackProperty: 'negativeFeedback', feedbackNode: Node.one('.challenge-message') } );

	lf.renderer.userAnswered.add( challengeFeedback.decorate.bind( challengeFeedback ) );

	lf.renderer.ioc.add( 'BinaryChoice', function( model:BinaryChoiceModel, slideRenderer:SlideRenderer ) {
		return new BinaryChoice( model, slideRenderer ).render() as BinaryChoice;
	});
	lf.renderer.ioc.alias( 'App\\BinaryChoice', 'BinaryChoice' );

	lf.renderer.ioc.add( 'BooleanBranch', function( model: BooleanBranchModel, slideRenderer: SlideRenderer ) {
		return new BooleanBranch( model, slideRenderer ).render() as BooleanBranch;
	});
	lf.renderer.ioc.alias( 'App\\BooleanBranch', 'BooleanBranch' );


	lf.renderer.show( first as Slide );
	

	var result = new ResultView();
	var certificateform = new CertificateFormView();

	certificateform.setData( setup.data( 'module' ) )
	certificateform.setCertificateUrl( decodeURIComponent(setup.data( 'certificateurl' )));

	result.gotoCertificate.add( function() {
		result.node.addClass( 'hidden' );
		Node.one('#result').empty();
		certificateform.render( Node.one('#result' ));
		result.node.removeClass('hidden');
	});

	setTimeout( function() {
		setSizes();
	}, 500)
};

function setSizes() {

	// determine header height
	// 992 is the medium breakpoint
	var headerheight = 100
	if( Window.viewport().width <= 992) {
		headerheight = 64
	}

	Node.js( 'layout' ).setAttribute( 'style', 'height:' + Window.viewport().height + 'px');
	if( Node.js( 'slide-renderer' )) Node.js( 'slide-renderer' ).setAttribute( 'style', 'height:' + (Window.viewport().height - headerheight) + 'px');
}

export function initFooter() {
	var popup = Node.js( 'language-chooser' );
	var infolayer = Node.js( 'info' );

	Node.js( 'languagechooser-link').click.add( function() {
		popup.toggleClass( 'hidden' );
	}, this )

	Node.js( 'info-link' ).click.add( function() {
		infolayer.toggleClass( 'hidden' );
	}, this );

	Node.js( 'close-layer' ).click.add( function() {
		infolayer.addClass( 'hidden' );
	}, this );

}