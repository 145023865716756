import QuestionModel from 'lf/slides/Question/QuestionModel';
import LernFragen from 'lf/LernFragen';


/**
 * A model class for DragDrop slides.
 */
export class BinaryChoiceModel extends QuestionModel {

	/**
	 * The constructor
	 */
	constructor( json ) {
		super( json );
		this.complete();
	}

	/**
	 * Complete and adjusts the model
	 */
	public complete() {
		
	}

	public selectChoice( selectedChoice:boolean ) {
		this.choice = selectedChoice;
		this.answered = true;

		this.fireUserInput();
	}

	public hasUserInput():boolean {
		return (this.choice === undefined)?false:true;
	};

	public isCorrect():boolean {
		return (this.choice === undefined) ? false : this.choice;
	};

	set choice( choice:boolean ) {
		this.set( 'userchoice', choice );
	}

	get choice(): boolean {
		return this.get( 'userchoice' );
	}

}

export default BinaryChoiceModel;