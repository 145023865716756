// import lib
import View from 'ln/view/View';
import LinkedList from 'ln/linkedlist/LinkedList';
import Node from 'ln/node/Node';
import Window from 'ln/node/Window';
import Signal from 'ln/signal/Signal';
import QuestionModel from 'lf/slides/Question/QuestionModel';
import SlideModel from 'lf/slides/Slide/SlideModel';
import SlideView from 'lf/slides/Slide/Slide';
import SlideRenderer from 'lf/sliderenderer/SlideRenderer';
import LernFragen from 'lf/LernFragen';
import BooleanBranchView from '../slides/BooleanBranch';
import BooleanBranchModel from '../slides/BooleanBranchModel';
import BinaryChoice from '../slides/BinaryChoice';
import BinaryChoiceModel from '../slides/BinaryChoiceModel';
import HotspotModel from 'lf/slides/HotSpot/HotspotModel';
import Hotspot from 'lf/slides/HotSpot/Hotspot';
import Decorator from 'lf/decorators/Decorator';

export interface ChallengeNavigationConfig {
	nextButtonKey?: string;
}

export interface ChallengeFinishedListener {
( model: SlideModel, view: SlideView ): void;
}

/**
 * A simple navigation that allows browsing through the slides without any contrains  
 */
class ChallengeNavigation extends View {

	protected slideRenderer: SlideRenderer;
	
	protected nextButton: Node;
	protected stopButton: Node;
	protected okayButton: Node;

	public challengeFinished: Signal<ChallengeFinishedListener | Decorator>;

	constructor( slideRenderer: SlideRenderer ) {
		super();

		this.slideRenderer = slideRenderer;
		this.defaultTemplate = 'lf.challenge-navigation';

		this.challengeFinished = new Signal<ChallengeFinishedListener | Decorator>();
	}

	/**
	 * Render the template for the navigation
	 */
	public init() {

		this.nextButton = this.node.js( 'next-button' );
		this.stopButton = this.node.js( 'stop-button' );
		this.okayButton = this.node.js( 'okay-button' );

		// register listeners
		//this.prevButton.click.add( this.onPrevious, this );
		this.nextButton.click.add( this.onNext, this );
		this.stopButton.click.add( this.onStop, this );
		this.okayButton.click.add( this.onOkay, this );

		// register signals on slide renderer
		this.slideRenderer.slideChanged.add( this.onSlideChanged, this );
		this.slideRenderer.userAnswered.add( this.onUserAnswered, this );
	}

	/*protected onPrevious() 
		this.goto( this.slideRenderer.currentModel, this.slideRenderer.currentModel.previous as SlideModel );
	}*/

	protected onNext() {
		var current = this.slideRenderer.currentModel as SlideModel;

		//somehow .next can be either null or undefined...
		if( current.next === null || current.next === undefined ) {
			this.challengeFinished.dispatch( current, this.slideRenderer.currentView );
		} else {
			if( current instanceof HotspotModel ) {
				if( current.isAnswered() ) {
					this.goto( this.slideRenderer.currentModel, this.slideRenderer.currentModel.next as SlideModel );
				} else {
					if( current.hasUserInput() && current instanceof QuestionModel && !current.answered ) {
						current.markAsAnswered();
					}
				}
			} else if( current instanceof BinaryChoiceModel ) {
				
				//current.markAsAnswered();
				this.goto( this.slideRenderer.currentModel, this.slideRenderer.currentModel.next as SlideModel );
			} else {
				this.goto( this.slideRenderer.currentModel, this.slideRenderer.currentModel.next as SlideModel );
			}
		}
	}

	protected onStop() {
		var view = <any>this.slideRenderer.currentView

		view.onChooseStop()
		if( view instanceof BooleanBranchView ) {
			this.onNext();
		} else {
			this.toggleButtons( view.model, view )
		 }

	}

	protected onOkay() {
		var view = <any>this.slideRenderer.currentView;

		view.onChooseOkay();
		
		if( !view.model.isCorrect() ) {
			this.onNext();
			// custom suva, slidemodel after hotspot case
			if( view.model.next ) {
				if( view.model.next.next ) {
					// even customer, since all SlideModels are instances of SlideModel I have to check whether its a SlideModel but not any other Model)
					if( view.model.next instanceof HotspotModel && (view.model.next.next instanceof SlideModel && !(view.model.next.next instanceof BooleanBranchModel) && !(view.model.next.next instanceof BinaryChoiceModel)) ){
						this.onNext();
					}
				}
			}
		}

		this.toggleButtons( view.model, view )
	}

	protected goto( current:SlideModel, next:SlideModel ) {

		// check if question and not answered
		if( current.hasUserInput() && current instanceof QuestionModel && !current.answered ) {
			current.markAsAnswered();
		}

		this.slideRenderer.show( next );
	}

	protected onSlideChanged( currentModel: SlideModel, currentView: SlideView ) {
		/*if( currentModel instanceof HotspotModel ) {
			var previous = currentModel.previous;
			
		}*/

		this.toggleButtons ( currentModel, currentView );
		var prompt = this.node.js( 'prompt' );
		prompt.addClass( 'hidden' );

		// show the thingy
		if( currentModel instanceof BooleanBranchModel || currentModel instanceof BinaryChoiceModel || currentModel instanceof HotspotModel) {
			var parent = Node.one( '.challenge-message' );
			var container;
			var text = currentModel.get('text');
			if( !text ) {
				text = 'Default challenge text';
			}

			// additional prompt
			if( !currentModel.isAnswered() ) {
				var sPrompt = currentModel.get( 'prompt' );
				if( sPrompt !== undefined && sPrompt !== '' ) {
					prompt.html = currentModel.get( 'prompt' );
					prompt.removeClass( 'hidden' );
				}
			}

			if( parent.hasChildren() ) {
				container = parent.children()[0];
				container.removeClass( '-wrong');
				container.removeClass( '-neutral');
				container.removeClass( '-correct');
			} else {
				container = Node.fromTag( 'div' );
				container.addClass( 'feedback-placeholder', 'feedback-panel' );
				parent.append( container );
			}
			container.addClass( '-neutral' );
			container.html = text;
		}

		if( !(currentModel instanceof BooleanBranchModel) && !(currentModel instanceof BinaryChoiceModel) && !(currentModel instanceof HotspotModel)) {
			if( currentModel instanceof SlideModel && currentModel.previous instanceof HotspotModel ) {
				var sPrompt = currentModel.previous.get('promptFeedback');
				if( sPrompt ) {
					prompt.html = String(sPrompt)
					prompt.removeClass( 'hidden' );
				}
			}
		}

		if( currentModel instanceof HotspotModel ) {
			// Top bar, see challenge-layout.styl for reference
			var topbar = 64
			if( Window.viewport().width > 992 ) topbar = 100

			// Main content padding, see challenge-layout.styl for reference
			var padding = 20 // double because top and bottom
			if( Window.viewport().width > 768 ) padding = 64

			var height = Window.viewport().height - topbar - padding;

			if( Node.js( 'points' )) {
				var imgheight = Node.js('points').one('.challenge-hotspotimage').native.height;
				
				if( imgheight < height && imgheight != 0 ) {
					height = imgheight
				}
				Node.js( 'points' ).setAttribute( 'style', 'height:' + height + 'px')
			}
		}
	}

	protected onUserAnswered( currentModel: SlideModel, currentView: SlideView ) {
		if( ( currentModel instanceof HotspotModel || currentModel instanceof BinaryChoiceModel ) && currentModel.answered ) {
			this.nextButton.removeClass( 'hidden' );
		}
		var prompt = this.node.js( 'prompt' );

		if( currentModel instanceof BinaryChoiceModel || currentModel instanceof HotspotModel ) {
			var feedback = currentModel.get( 'promptFeedback' );
			if( feedback != undefined && feedback !== '' ) {
				prompt.html = feedback as string
				prompt.removeClass( 'hidden' )
			}
		}
	} 

	protected toggleButtons( currentModel: SlideModel, currentView: SlideView ) {
		// check if one of the nav buttons has to be hidden or shown
		var answered = true;
		var correct = false;
		if( currentModel instanceof QuestionModel ) answered = currentModel.answered;
		if( currentModel instanceof QuestionModel ) correct = currentModel.isCorrect();

		if( ( currentModel.modelName == 'App\\BooleanBranch' && correct ) || ( currentModel.modelName == 'App\\BinaryChoice' && !answered ) ) {
			this.stopButton.removeClass( 'hidden' );
			this.okayButton.removeClass( 'hidden' );
			this.nextButton.addClass( 'hidden' );
		} else { //if( currentModel instanceof BinaryChoiceModel ) {
			this.stopButton.addClass( 'hidden' ); 
			this.okayButton.addClass( 'hidden' );
		}
	}

}

export default ChallengeNavigation;