// import lib
import View from 'ln/view/View';
import Scanner from 'ln/view/Scanner';
import Node from 'ln/node/Node';
//import Slide from '../slides/Slide/SlideModel';
//import SlideRenderer from './sliderenderer/SlideRenderer';
import Mapper from 'ln/model/Mapper';
/*import { common as commonModels } from './setup/models';
import { all as commonViews } from './setup/lernfragen';
import { common as commonSlides } from './setup/slides';*/

/**
 * Main class for LernFragen quizzes
 */
class CertificateForm extends View {
    
	private data:{};
	
	private certificatelanguage:string;
	private certificateUrl:string;

	constructor() {
		super();

		this.data = {};
		this.certificateUrl = '';
		this.certificatelanguage = '';

		this.defaultTemplate = 'lf.certificate';
	}

	public init() {
		this.node.js('first').js('next').click.add( function() {
			if( this.certificatelanguage == '') return;

			this.node.js('first').addClass('hidden');
			this.node.js('second').removeClass('hidden');
		}, this);
		this.node.js('second').js('next').click.add( function() {
			if( this.node.js('second').one( "[name=firstname]" ).value == '' || this.node.js('second').one( "[name=lastname]" ).value == '' ) return;

			this.node.js('second').addClass('hidden');
			this.node.js('third').removeClass('hidden');
		}, this);
		this.node.js('third').js('download').click.add( function() {

			// get input values
			var firstname = this.node.js('second').one( "[name=firstname]" ).value
			var lastname = this.node.js('second').one( "[name=lastname]" ).value
			var moduleslug = this.data.slug

			var printlink = this.node.js('third').js('download').getAttribute('data-printlink');
			var data = {
				slug: moduleslug,
				lang: this.certificatelanguage,
				firstname,
				lastname
			}
			var certifciateurl = this.certificateUrl.replace( '[[ hash ]]', this.b64EncodeUnicode( JSON.stringify( data ) ) );
			printlink = printlink + certifciateurl
			window.location.href = printlink;
		}, this);

		this.node.all( "[js=lng-button]").forEach( function( node:Node, index:number ) {
			node.click.add( function( target:Node ) {
				
				this.setCertificateLanguage( target.getAttribute('data-code') );
				target.addClass('-active');
			}, this);
		}, this);

	}

	public setCertificateLanguage( code:string ) {
		this.certificatelanguage = code;

		//remove all the others
		this.node.all( "[js=lng-button]").forEach( function( node:Node, index:number ) {
			if( node.getAttribute( 'data-code' ) !== code ) {
				node.removeClass( '-active' );
			}
		}, this);
	}

	b64EncodeUnicode(str) {
		return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
			return String.fromCharCode( '0x' + p1 as any );
		}));
	}

    public setData( data:{} ) {
		this.data = data;
	}
	
	public setCertificateUrl( url:string ) {
		this.certificateUrl = url;
	}
    
    public renderData() {
        return this.data;
    }
}


export default CertificateForm;